import * as React from "react";
import { graphql } from "gatsby";

import { } from "./main.css"


export default function Main() {


}

export const query = graphql`
  fragment v on Main {
    id
  }
`;