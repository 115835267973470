import * as React from "react";
import { graphql } from "gatsby";
import { Section, Container, SuperHeading, Box } from "./ui";
import { widths } from "./ui.css";
import {diagonalSplitBlock} from "./coming-soon.css"

export default function ComingSoon(props) {
  return (
    <Section>
      <Container width="fullbleed">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          {" "}
          <Box>{}</Box>
          <div class="diagonalSplitBlock"></div>
          <Box background="active">{}<SuperHeading >{`...  ${props.comingSoon}`}</SuperHeading></Box>
        </div>
      </Container>
    </Section>
  );
}

export const query = graphql`
  fragment HomepageComingSoonContent on ComingSoon {
    id
    comingSoon
  }
`;
