import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"


export default function Homepage(props) {


  return (
    
        <Layout >
        {sections.Main()}
        </Layout>
      )
    }

// export const query = graphql`
// query comingSoonInfo {
//   comingSoon {
//     comingSoon
//   }
// }
// `